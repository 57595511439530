import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import { useGuildStore } from '@/js/store/quests/dashboard/GuildStore';
import { useDeviceSize } from '@/js/composables/useDeviceSize';
import { useUserStore } from '@/js/store/quests/dashboard/UserStore';

export const useDashboardBarStore = defineStore('MenuStore', () => {
    const authStore = useAuthStore();
    const guildStore = useGuildStore();
    const userStore = useUserStore();
    const { isMobileOrTablet } = useDeviceSize();

    const isAllowedPage = ref(false);

    const isAdmin = computed(() => authStore.isAdmin());
    const isOwner = computed(() => !!guildStore.guildInfo?.is_owner);
    const hasOwnedGuilds = computed(() => userStore.user?.data.owned_guilds?.length > 0);
    const isStudioUnlocked = computed(() => authStore.user?.role === 'guild_owner');
    const hasPermissions = computed(() => isOwner.value || isAdmin.value || hasOwnedGuilds.value || isStudioUnlocked.value);

    const isBarVisible = computed(() => {
        if (!isAllowedPage.value) {
            return false;
        }

        if (isMobileOrTablet.value) {
            return false;
        }

        return hasPermissions.value;
    });

    const showDashboardBar = () => {
        if (!authStore.isUserLoggedIn || !hasPermissions.value) {
            return;
        }

        isAllowedPage.value = true;
    };
    const hideDashboardBar = () => {
        isAllowedPage.value = false;
    };

    return {
        isBarVisible,
        showDashboardBar,
        hideDashboardBar,
    };
});
